
<template>
 <div class="HomePage">
  <div class="break"></div>
 <MarqueeText></MarqueeText>
   <div class="break"></div>
 <div ref="hero" class="Hero">
   <div class="MainPageText">
     <div class="TitleContainer">
       <span class="Title">Residential & Commercial Roofing Experts </span>
     </div>
     <div class="SubTitleContainer">
       <h2 class="subTitle">
         Your trusted source for quality roofing in Southwest Michigan.
       </h2>
       <ContactForm :modal="this.modal"></ContactForm>  
     </div>
   </div>
   <div>
   <div v-if="showContactForm" class="floatingContact">
     <ContactForm :modal="this.modal"></ContactForm>
   </div>
 
 </div>
 </div>



   <div class="break"></div>
   <div class="Services">
    <span class="ServicesTitle">Check out our Google Reviews!</span>
   <CustomerTestimonials/>
   <Credentials/>
   </div>
   <div class="break"></div>
   <LicensedInsured/>
   <div class="break"></div>
 <div class="Services">
   <span class="ServicesTitle">Services</span>
   <div class="serviceSelection">
     <PhotoCard v-for="(service, index) in services" :key="index"
     :Services="service" @click="goToPage(service.path)">
    </PhotoCard>
   </div>

 </div>
 <div class="break"></div>
 <div id="footer">
   <PageFooter/>
 </div>

 </div>
 </template>
 
 <script>
 import ContactForm from '@/components/ContactForm.vue';
 import PhotoCard from '@/components/NewServiceSelector.vue';
 import MarqueeText from '@/components/MarqueeText.vue';
 import PageFooter from '@/components/PageFooter.vue';
 import LicensedInsured from '@/components/LicensedInsured.vue';
 import CustomerTestimonials from '@/components/CustomerTestimonials.vue';
 import Credentials from '@/components/MyCredentials.vue';
 
 export default {
   name: 'HomePage',
   metaInfo: {
     link: [
     { rel: 'preload', href: require('@/assets/RoofingReplacementPhoto.jpg'), as: 'image' },
     { rel: 'preload', href: require('@/assets/RoofingRepairPhoto.jpg'), as: 'image' },
     { rel: 'preload', href: require('@/assets/Home 3.jpg'), as: 'image' },
   ],
     title: 'Roofing Services | Homes Roofing Co. | Niles, MI',
     meta: [
       {
         name: 'Homes Landing Page',
         content: 'Get high-quality roofing services from Homes Roofing in Niles, Michigan. Our experienced team provides expert installation, repair, and maintenance services for residential and commercial properties. Contact us today for a free estimate.',
       },
     ],
   },
   data(){
     return{
       modal: false,
       services: [

       {
         imageSrc: require('@/assets/Repairs/Repair1.jpg'),
         title: 'Emergency Repairs',
         path: '/repair-projects',
       },

       {
         imageSrc: require('@/assets/Siding/Siding 1.jpg'),
         title: 'Siding',
         path: '/siding-services',
       },

      //  {
      //    imageSrc: require('@/assets/Siding/Siding 1.jpg'),
      //    title: 'Gutters',
      //    path: '/gutter-services',
      //  },

       {
         imageSrc: require('@/assets/RoofingRepairPhoto.jpg'),
         title: 'Roofing Replacement',
          path: '/roofing-projects',
       },

     ],
     showContactForm: true,
     }
   },
 
   components: {
     ContactForm,
     PhotoCard,
     PageFooter,
     LicensedInsured,
    CustomerTestimonials,
    Credentials,
     MarqueeText,
   },
   mounted() {
     console.log("mounted");
     window.addEventListener('scroll', this.handleScroll.bind(this));
},
   beforeUnmount() {
     window.removeEventListener('scroll', this.handleScroll.bind(this));
   },
   methods: {
   handleScroll() {
    if (this.$refs.scrollElement) {
    // const scrollPosition = this.$refs.scrollElement.offsetHeight;
    // const heroHeight = this.$refs.hero.offsetHeight;
    this.showContactForm = true
  }

   },
   goToPage(path) {
      this.$router.push(path);
    },
 },

 }
 </script>
 
 <style>
   .HomePage {
    margin-top: 93px;
  } 
  @media (max-width: 880px) {
    .HomePage {
    margin-top: 168px;
  } 

 }

.Hero {
 display: flex;
 flex-direction: column;
 align-content: center;
 justify-content: center;
 padding: 50px;
 background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../assets/Cover_Photo_3.jpg');

 background-size: cover; /* Cover the entire .Hero area */
 background-position: center; /* Center the image */
 background-repeat: no-repeat; /* Do not repeat the image */
}



/* Desktop Specific */
@media (min-width: 880px) {

 .MainPageText {
   display: flex;
   text-align: center;
   min-height: 400px;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

 .TitleContainer{
   display: flex;
   align-items: center;
 }
 .SubTitleContainer{
   display: flex;
   flex-direction: column;
   align-items: center;
   
 }
}

.MainPageText {
 display: flex;
 text-align: center;
 min-height: 400px;
 flex-direction: column;
 justify-content: center;
}

@media screen and (max-width: 880px){
 .TitleContainer{
   padding-bottom: 15px;
 }

 .SubTitleContainer {
   display: flex;
   flex-direction: column;
   align-items: center;
 }
}

.Title {
 font-size: 2.2em;
   color: #ffffff;
   margin-bottom: 20px;
   font-family: 'Roboto', sans-serif;
}

.subTitle {
 font-family: 'Open Sans', sans-serif; /* Use Open Sans font */
 font-size: 1.5em; /* Increase font size */
 font-weight: bold; /* Make text bold */
 letter-spacing: 1px; /* Add some letter spacing */
 color: #ffffff; /* White text */
}
 
.ServicesTitle{
 color: #fff;
   text-shadow: 2px 2px 0px #000000;
   letter-spacing: 2px;
   font-size: 3em;
   position: relative;
   z-index: 2;
}

.Bold{
 font-weight: bold;
}

.Services{
  padding-top: 10px;
   display: flex;
   flex-direction: column;
   background-image: linear-gradient(to right, #002655, #89c2fc);
}


.Services .Title {
 width: 100%;
 display: flex;
 justify-content: center;
 padding: 15px;
 font-size: -webkit-xxx-large;
}
.serviceSelection {
 display: flex;
 justify-content: space-around;
 flex-direction: row;
 flex-wrap: wrap;
}

.photo-card {
   display: flex;
   flex-direction: column;
   color: #bdbdbd;
   justify-content: center;
   align-items: center;
}


.HomeImage{
 height: 100%;
 width: 100%;
 border-radius: 10px;
 object-fit: cover;

 @media (max-width: 880px) {
   margin-bottom: 20px;

 }
}
.break{
 width: 100%;
 border-bottom: #8d0808 .25em solid;
}
.show-contact-form {
 position: fixed;
 bottom: 20px;
 right: 20px;
 display: block;
}
.floatingContact {

 .contact-button {

  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #950d0f; /* Change to your preferred color */
  color: white;
  border: none;
  border-radius: 50%;
  width: 90px;
  height: 70px;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  animation: pulse 9s infinite;

   @media (min-width: 880px){
    width: 150px;
    right: 8%;
    top: 10px;
    z-index: 100;
   }
  }
}


 
 </style>
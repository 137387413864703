<template>
    <div class="cmsPage">
      <div class="marqueeSection">
        <div class="exampleText">
          <h2>Example Marquee Text</h2>
          <MarqueeText />
        </div>

        <div class="updateButton">
          <textarea v-model="newText" rows="5" cols="100"></textarea>
          <button @click="updateMarqueeText">Update</button>
        </div>

      </div>

    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import MarqueeText from '../../MarqueeText.vue';
  export default {
    data() {
      return {
        newText: '',
      };
    },
    components: {
      MarqueeText,
    },
    methods: {
      async updateMarqueeText() {
        try {
          const apiUrl = process.env.VUE_APP_API_BASE_URL + '/nodejsa2/marquee-text/';
          const response = await axios.post(apiUrl , 
          { text: this.newText }, 
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
          );
          console.log('Marquee text updated to: ' + response.data.text);
          window.location.reload();
        } catch (error) {
          console.error('Error updating marquee text:', error);
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .marqueeSection {
    width: 50%;
    margin-top: 5%;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;


    .exampleText {
      display: flex;
      flex-direction: column;
      left: auto;
      width: 100%;
    }
  }
  .updateButton {
    display: flex;
      margin-top: 1rem;
      padding: 0.5rem 1rem;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      width: 100%;
      
      button {
        background-color: #ba0f0fa0;
        font-size: 18px;
        font-weight: bolder;
        border: none;
        border-radius: 5px;
        margin-left: 1rem;
        cursor: pointer;
        width: 25%;
      } 
  }
  </style>
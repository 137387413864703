import { createRouter, createWebHistory } from 'vue-router'
import HomePage from './views/HomePage.vue'
import AboutPage from './views/AboutUsPage.vue'
import RoofingPage from './views/PhotoGallery/RoofingPage.vue'
import RepairsPage from './views/PhotoGallery/RepairsPage.vue'
import SidingPage from './views/PhotoGallery/SidingPage.vue'
import GutterPage from './views/PhotoGallery/GutterPage.vue'
import Login from './components/Admin/LoginPage.vue'
import CMS from './components/Admin/CMS.vue'

const routes = [
  { path: '/', component: HomePage },
  { path: '/about', component: AboutPage },
  { path: '/roofing-projects', component: RoofingPage },
  { path: '/repair-projects', component: RepairsPage },
  { path: '/siding-services', component: SidingPage },
  { path: '/gutter-services', component: GutterPage },
  { path: '/login', component: Login },
  { path: '/cms', component: CMS },


]

const router = createRouter({
    history: createWebHistory(),
    routes
  })

  


export default router